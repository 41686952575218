<template>
  <section>
    <Products v-if="$route.name === client_products_routes.Products" />
    <Default_page v-else-if="$route.params.type"/>
    <Home v-else/>
  </section>
</template>

<script>
import { client_products_routes } from "../../../../data/routes/content-routes"

const Default_page = () => import("./Default_page")
const Products = () => import("./Products")
const Home = () => import("./Home")


export default {
  components: {
    Default_page,
    Products,
    Home
  },
  data() {
    return { client_products_routes }
  }
}
</script>

<style lang="scss">
  @use "../../../../styles/_global-constants" as *;

  .wrapper--orders {
    padding: 20px 0; // Overwriting default styles

    @media (max-width: $maximum-width) {
      padding: 20px;
    }
  }
</style>
